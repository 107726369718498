import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import useAuth from '../services/useAuth'; // Usar el hook useAuth

const Navbar = () => {
  const { user, role, loading } = useAuth(); // Obtener el usuario, rol y estado de carga desde useAuth
  const navigate = useNavigate();
  const auth = getAuth();

  // Cerrar sesión
  const handleLogout = async () => {
    await signOut(auth); // Cerrar sesión
    navigate('/login'); // Redirigir al login después de cerrar sesión
  };

  if (loading) {
    return <div>Cargando...</div>; // Mostrar un mensaje mientras se cargan los datos
  }

  return (
    <nav className="navbar">
      <div className="navbar-links">
        <Link to={user ? "/dashboard" : "/login"}>Inicio</Link>
        {user && (
          <>
            <Link to="/products">Productos</Link>
            {role === 'administrador' && <Link to="/manage-users">Gestionar Usuarios</Link>}
          </>
        )}
      </div>

      {user && (
        <div className="user-info">
          {/* Mostrar el correo del usuario logueado */}
          <p className="user-email">{user.email}</p> 
          <button className="logout-btn" onClick={handleLogout}>Cerrar Sesión</button>
        </div>
      )}

      {!user && <Link to="/login">Iniciar Sesión</Link>}
    </nav>
  );
};

export default Navbar;
