import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Dashboard from './pages/Dashboard';
import Login from './components/Login';
import Products from './pages/Products';
import ManageUsers from './pages/ManageUsers'; 
import { getAuth, onAuthStateChanged } from 'firebase/auth'; 
import PrivateRoute from './components/PrivateRoute'; 

const App = () => {
  const [user, setUser] = useState(null); // Estado del usuario
  const [loading, setLoading] = useState(true); // Estado de carga
  const auth = getAuth(); // Instancia de autenticación

  // Verificar el estado de autenticación del usuario
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser); // Establecer el usuario actual en el estado
      setLoading(false); // Detener la carga cuando se obtiene el estado
    });

    return () => unsubscribe(); // Limpiar el listener cuando el componente se desmonte
  }, [auth]);

  if (loading) {
    return <div>Cargando...</div>; // Mostrar un mensaje mientras carga el estado
  }

  return (
    <Router>
      <div>
        <Navbar user={user} /> {/* Pasar el estado del usuario al Navbar */}
        <div className="container mx-auto mt-4">
          <Routes>
            {/* Ruta de inicio de sesión */}
            <Route path="/login" element={<Login />} />
            
            {/* Ruta privada para el dashboard */}
            <Route 
              path="/dashboard" 
              element={
                <PrivateRoute user={user}>
                  <Dashboard />
                </PrivateRoute>
              } 
            />
            {/* Ruta privada para productos */}
            <Route 
              path="/products" 
              element={
                <PrivateRoute user={user}>
                  <Products />
                </PrivateRoute>
              } 
            />
            {/* Ruta privada para gestionar usuarios */}
            <Route 
              path="/manage-users" 
              element={
                <PrivateRoute user={user}>
                  <ManageUsers />
                </PrivateRoute>
              } 
            />
            {/* Redirigir a /dashboard si el usuario está autenticado, de lo contrario a /login */}
            <Route path="/" element={<Navigate to={user ? "/dashboard" : "/login"} />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
