// src/services/useAuth.js

import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Ajusta la ruta de acuerdo a tu estructura

const useAuth = () => {
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(null); // Almacena el rol del usuario
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        // Verificar el rol en Firestore
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          setRole(userDoc.data().role); // Almacenar el rol
        }
      }
      setLoading(false); // Termina de cargar
    });

    return () => unsubscribe();
  }, []);

  return { user, role, loading };
};

export default useAuth;
