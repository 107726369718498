import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'; // Importa Firebase Storage
import { db } from '../firebase'; // Importa la base de datos de Firebase

const Products = () => {
  const [brand, setBrand] = useState('');
  const [category, setCategory] = useState('');
  const [description, setDescription] = useState('');
  const [imageUrls, setImageUrls] = useState([]); // Para almacenar las URLs generadas de Firebase Storage
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [mainImageIndex, setMainImageIndex] = useState(0); // Índice de la imagen principal
  const [products, setProducts] = useState([]);
  const [editProductId, setEditProductId] = useState(null); // Estado para editar productos
  const [isUploading, setIsUploading] = useState(false); // Estado para manejar la subida de imágenes

  const storage = getStorage(); // Configura Firebase Storage

  // Obtener productos de Firestore
  useEffect(() => {
    const fetchProducts = async () => {
      const querySnapshot = await getDocs(collection(db, 'products'));
      const productList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setProducts(productList);
    };

    fetchProducts();
  }, []);

  // Subir múltiples imágenes a Firebase Storage y obtener las URLs
  const handleMultipleImageUpload = async (files) => {
    const promises = [];
    setIsUploading(true); // Activar el estado de subida
    setImageUrls([]); // Limpiar las URLs previas

    for (let file of files) {
      const storageRef = ref(storage, `images/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      const promise = new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            // Puedes manejar el progreso si es necesario
          },
          (error) => {
            console.error("Error al subir la imagen: ", error);
            reject(error);
          },
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(downloadURL);
          }
        );
      });
      promises.push(promise);
    }

    try {
      const urls = await Promise.all(promises);
      setImageUrls(urls); // Guardar las URLs de las imágenes generadas
      setIsUploading(false); // Desactivar el estado de subida
    } catch (error) {
      console.error("Error al subir imágenes: ", error);
      setIsUploading(false);
    }
  };

  // Agregar producto a Firestore
  const handleAddProduct = async () => {
    try {
      await addDoc(collection(db, 'products'), {
        brand,
        category,
        description,
        images: imageUrls, // Usar las URLs de las imágenes generadas
        mainImageIndex, // Guardar el índice de la imagen principal
        name,
        price: price ? parseFloat(price) : '' // Guardar el precio como número si está presente
      });

      // Limpiar los campos del formulario después de añadir el producto
      setBrand('');
      setCategory('');
      setDescription('');
      setImageUrls([]);
      setMainImageIndex(0); // Resetear el índice de la imagen principal
      setName('');
      setPrice('');

      // Actualizar la lista de productos
      const querySnapshot = await getDocs(collection(db, 'products'));
      const updatedProductList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setProducts(updatedProductList);
    } catch (error) {
      console.error('Error al agregar el producto:', error);
    }
  };

  // Preparar los datos para editar un producto
  const handleEditProduct = (product) => {
    setEditProductId(product.id);
    setBrand(product.brand || '');
    setCategory(product.category || '');
    setDescription(product.description || '');
    setImageUrls(product.images || []); // Mostrar las imágenes existentes
    setMainImageIndex(product.mainImageIndex || 0); // Índice de la imagen principal
    setName(product.name || '');
    setPrice(product.price || '');
  };

  // Guardar cambios del producto editado
  const handleSaveEdit = async () => {
    if (!editProductId) return;

    try {
      const productRef = doc(db, 'products', editProductId);
      await updateDoc(productRef, {
        brand,
        category,
        description,
        images: imageUrls, // Actualizar las imágenes con las URLs generadas
        mainImageIndex, // Actualizar el índice de la imagen principal
        name,
        price: price ? parseFloat(price) : ''
      });

      // Limpiar el estado y cerrar el modo de edición
      setEditProductId(null);
      setBrand('');
      setCategory('');
      setDescription('');
      setImageUrls([]);
      setMainImageIndex(0);
      setName('');
      setPrice('');

      // Actualizar la lista de productos después de la edición
      const querySnapshot = await getDocs(collection(db, 'products'));
      const updatedProductList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setProducts(updatedProductList);
      
      alert("Producto actualizado correctamente");
    } catch (error) {
      console.error('Error al actualizar el producto:', error);
    }
  };

  // Eliminar producto de Firestore
  const handleDeleteProduct = async (productId) => {
    try {
      await deleteDoc(doc(db, 'products', productId));

      // Actualizar la lista de productos
      const updatedProductList = products.filter(product => product.id !== productId);
      setProducts(updatedProductList);
    } catch (error) {
      console.error('Error al eliminar el producto:', error);
    }
  };

  // Función para manejar la selección de archivos múltiples
  const handleFileChange = (e) => {
    const files = e.target.files;
    handleMultipleImageUpload(files); // Llamar a la función de subida de múltiples imágenes
  };

  return (
    <div className="products-container">
      <h2>Gestionar Productos</h2>

      {/* Formulario para agregar o editar productos */}
      <div className="add-product-form">
        <input 
          type="text" 
          placeholder="Marca" 
          value={brand} 
          onChange={(e) => setBrand(e.target.value)} 
        />
        <input 
          type="text" 
          placeholder="Categoría" 
          value={category} 
          onChange={(e) => setCategory(e.target.value)} 
        />
        <input 
          type="text" 
          placeholder="Descripción" 
          value={description} 
          onChange={(e) => setDescription(e.target.value)} 
        />

        {/* Input para subir múltiples imágenes */}
        <input 
          type="file" 
          multiple // Permitir la selección de múltiples archivos
          onChange={handleFileChange} 
        />

        {isUploading && <p>Subiendo imágenes...</p>} {/* Muestra un mensaje si se están subiendo imágenes */}

        {/* Selector para la imagen principal */}
        {imageUrls.length > 0 && (
          <div>
            <label>Seleccionar imagen principal:</label>
            <select
              value={mainImageIndex}
              onChange={(e) => setMainImageIndex(parseInt(e.target.value, 10))}>
              {imageUrls.map((url, index) => (
                <option key={index} value={index}>
                  Imagen {index + 1}
                </option>
              ))}
            </select>
          </div>
        )}
        
        <input 
          type="text" 
          placeholder="Nombre del producto" 
          value={name} 
          onChange={(e) => setName(e.target.value)} 
        />
        <input 
          type="number" 
          placeholder="Precio del producto" 
          value={price} 
          onChange={(e) => setPrice(e.target.value)} 
        />

        {/* Mostrar botón de guardar si estamos editando, de lo contrario, mostrar añadir */}
        {editProductId ? (
          <button onClick={handleSaveEdit}>Guardar Cambios</button>
        ) : (
          <button onClick={handleAddProduct}>Añadir Producto</button>
        )}
      </div>

      <ul className="product-list">
        {products.map((product) => (
          <li key={product.id}>
            {product.name} - {product.price} €
            <button onClick={() => handleEditProduct(product)}>Editar</button>
            <button onClick={() => handleDeleteProduct(product.id)}>Eliminar</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Products;
