import React, { useState, useEffect, useCallback } from 'react';
import { getAuth, createUserWithEmailAndPassword, deleteUser, signInWithEmailAndPassword } from 'firebase/auth';
import { db } from '../firebase';
import { collection, getDocs, doc, updateDoc, deleteDoc, setDoc } from 'firebase/firestore';
import useAuth from '../services/useAuth'; // Usar el hook useAuth

const ManageUsers = () => {
  const { role, loading: authLoading } = useAuth(); // Obtener el usuario, rol y estado de carga desde useAuth
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [roleToAdd, setRoleToAdd] = useState('ayudante'); // Estado para el rol del usuario
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true); // Estado de carga
  const auth = getAuth();

  // Función para obtener la lista de usuarios desde Firestore
  const fetchUsers = useCallback(async () => {
    try {
      setLoading(true); // Empezamos cargando
      const querySnapshot = await getDocs(collection(db, 'users'));
      const userList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUsers(userList);
    } catch (error) {
      console.error('Error al obtener usuarios:', error);
    } finally {
      setLoading(false); // La carga ha terminado
    }
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  // Evitar el envío automático del formulario al cambiar de pestaña o presionar enter accidentalmente
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    await handleAddUser();
  };

  // Función para agregar usuario tanto en Firebase Authentication como en Firestore
  const handleAddUser = async () => {
    setError('');
    setSuccess('');
    const currentAdminUser = auth.currentUser; // Guardar el usuario administrador actual

    try {
      if (role !== 'administrador') {
        setError('No tienes permisos para agregar usuarios.');
        return;
      }

      // Crear usuario en Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const userId = userCredential.user.uid;

      // Agregar el usuario en Firestore
      await setDoc(doc(db, 'users', userId), {
        uid: userId,
        email,
        role: roleToAdd, // Guardar el rol seleccionado
      });

      // Volver a iniciar sesión con el administrador después de crear el usuario
      if (currentAdminUser) {
        await signInWithEmailAndPassword(auth, currentAdminUser.email, prompt('Introduce la contraseña de administrador'));
      }

      setSuccess('Usuario creado correctamente');
      setEmail('');
      setPassword('');
      setRoleToAdd('ayudante');

      // Actualizar la lista de usuarios
      fetchUsers();
    } catch (error) {
      // Manejo de errores específicos
      if (error.code === 'auth/email-already-in-use') {
        setError('Este correo electrónico ya está en uso. Intenta con otro.');
      } else if (error.code === 'auth/invalid-email') {
        setError('El formato del correo electrónico no es válido.');
      } else if (error.code === 'auth/weak-password') {
        setError('La contraseña es demasiado débil. Intenta con una contraseña más segura.');
      } else {
        setError('Error al crear el usuario. Verifica los datos ingresados.');
      }
      console.error('Error al crear usuario:', error);
    }
  };

  // Cambiar rol del usuario en Firestore
  const handleChangeRole = async (userId, newRole) => {
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        role: newRole,
      });
      fetchUsers(); // Actualizar la lista de usuarios después del cambio
    } catch (error) {
      console.error('Error al cambiar el rol:', error);
    }
  };

  // Eliminar usuario tanto de Authentication como de Firestore
  const handleDeleteUser = async (userId, authUid) => {
    try {
      if (role !== 'administrador') {
        setError('No tienes permisos para eliminar usuarios.');
        return;
      }

      // Eliminar el usuario de Firestore
      await deleteDoc(doc(db, 'users', userId));

      // Eliminar el usuario de Firebase Authentication
      const userToDelete = auth.currentUser;
      if (userToDelete && userToDelete.uid === authUid) {
        await deleteUser(userToDelete);
      }

      setUsers(users.filter((user) => user.id !== userId)); // Actualizar la lista de usuarios
    } catch (error) {
      console.error('Error al eliminar el usuario:', error);
    }
  };

  // Mostrar mensaje de carga mientras se obtienen los datos
  if (loading || authLoading) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="manage-users-container">
      <h2>Gestionar Usuarios</h2>
      {error && <p>{error}</p>}
      {success && <p>{success}</p>}
      <form onSubmit={handleFormSubmit} autoComplete="off">
        <input
          type="email"
          placeholder="Correo electrónico del usuario"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Contraseña del usuario"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <select value={roleToAdd} onChange={(e) => setRoleToAdd(e.target.value)} required>
          <option value="ayudante">Ayudante</option>
          <option value="administrador">Administrador</option>
        </select>
        <button type="submit" disabled={role !== 'administrador' || loading}>
          Agregar Usuario
        </button>
      </form>

      <h3>Lista de Usuarios</h3>
      <ul>
        {users.map((user) => (
          <li key={user.id}>
            {user.email} - {user.role}
            {user.role !== 'administrador' && role === 'administrador' && (
              <>
                <button onClick={() => handleChangeRole(user.id, 'administrador')}>Hacer Administrador</button>
                <button onClick={() => handleChangeRole(user.id, 'ayudante')}>Hacer Ayudante</button>
              </>
            )}
            {user.role !== 'administrador' && role === 'administrador' && (
              <button onClick={() => handleDeleteUser(user.id, user.uid)}>Eliminar</button>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ManageUsers;
