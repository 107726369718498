import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const PrivateRoute = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Estado de carga
  const [error, setError] = useState(null); // Estado para manejar errores

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser); // Establecer el usuario autenticado
        setError(null); // Limpiar el error en caso de éxito
      } else {
        setUser(null); // Si no está autenticado, limpiar el usuario
      }
      setLoading(false); // Detener el estado de carga
    }, (error) => {
      setError('Error al verificar la autenticación.');
      setLoading(false); // Detener el estado de carga en caso de error
    });

    // Limpiar el listener cuando el componente se desmonta
    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Cargando...</div>; // Mostrar un indicador de carga mientras se determina el estado
  }

  if (error) {
    return <div>{error}</div>; // Mostrar un mensaje de error en caso de fallo en la autenticación
  }

  // Si hay un usuario autenticado, renderizar el contenido protegido, de lo contrario redirigir a /login
  return user ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
