import React from 'react';
import { Link } from 'react-router-dom';

const Dashboard = () => {
  return (
    <div className="dashboard-container">
      <h2>Panel de administración</h2>
      <ul>
        <li><Link to="/products">Gestionar productos</Link></li>
        {/* Aquí puedes añadir más funcionalidades en el futuro */}
      </ul>
    </div>
  );
};

export default Dashboard;
