import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyB5P1-XYWqXR7jBUibbE14T1M4oanUcpYE",
  authDomain: "real-185.firebaseapp.com",
  projectId: "real-185",
  storageBucket: "real-185.appspot.com",
  messagingSenderId: "945474728541",
  appId: "1:945474728541:web:90a520b48db73a4cab13b4",
  measurementId: "G-7NRRLELKX2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };
